import { language } from '@/utils/language';
import { Message } from "@arco-design/web-react/es/index";

//根据目标key和当前数据以及类型，得出值。
//如果是社媒，外层没有匹配到的话，需要取value1内的值。如果不是社媒，则直接去外层的值
const getFieldVal = function (targetKey, obj) {
  var val = '';
  if (obj.serviceType == 7) {
    //如果社媒，则外层没有去取里层
    if (obj['value1']) {
      val = obj[targetKey] || obj['value1'][targetKey];
    } else {
      val = obj[targetKey];
    }
  } else {
    val = obj[targetKey];
  }
  return val || '无';
};
const copyFun = function (textToCopy) {
  // 创建一个临时的textarea元素来容纳文本
  var textarea = document.createElement("textarea");
  textarea.value = textToCopy;
  document.body.appendChild(textarea);
  textarea.select();

  // 使用document.execCommand('copy')方法执行复制操作
  var successful = document.execCommand('copy');

  // 从DOM中移除临时的textarea元素
  document.body.removeChild(textarea);
};

//工单获取关键词类型
const getReadKeyType = function (key) {
  var type = 'str';
  if (key == 'tag' || key == 'infringement' || key == 'evidence' || key == 'createUserId' || key == 'createUserName' || key == 'authorizationFile' || key == 'leakage' || key == 'leakageContent' || key == 'discoveryTime' || key == 'finishTime' || key == 'dealTime' || key == 'pauseTime' || key == 'pauseReason' || key == 'rejectReason' || key == 'assetName') {
    type = key;
  }
  return type;
};
//获取关键词类型
const getKeyType = function (key) {
  var type = 'str';
  if (key == 'tag' || key == 'infringement' || key == 'evidence' || key == 'brandId' || key == 'brandName' || key == 'createUserId' || key == 'createUserName' || key == 'assetName' || key == 'appName' || key == 'authorizationFile' || key == 'leakage' || key == 'leakageContent' || key == 'platform') {
    type = key;
  }
  return type;
};
//查看数组中是否包含指定元素
const indexOf = function (arr, str, key) {
  if (key) {
    var returnFlag = false;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][key] == str) {
        returnFlag = true;
        break;
      }
    }
    return returnFlag;
  } else {
    return arr.indexOf(str) > -1 ? true : false;
  }
};
//根据状态判断名称
const getStatuName = function (val, query) {
  var name = '';
  if (query && query.deleted) {
    return '已关闭';
  }
  switch (parseInt(val)) {
    case 2:
      name = '待处理';
      break;
    case 3:
      name = '已忽略';
      break;
    case 4:
      name = '待审核';
      break;
    case 5:
      name = '处置中';
      break;
    case 6:
      name = '已暂停';
      break;
    case 7:
      name = '已完成';
      break;
    case 8:
      name = '已撤销';
      break;
    case 9:
      name = '审核未通过';
      break;
    case 11:
      name = '重开待审核';
      break;
    case -1:
      name = '已超时';
      break;
    case -2:
      name = '已关闭';
      break;
    case 13:
      name = '待内部审核';
      break;
    case 14:
      name = '内部审核驳回';
      break;
  }
  return name;
};

/*
* 获取uuid
* @len 长度
* @radix 进制
*  getUUID(8, 2)   // 01001010
*  getUUID(8, 10)  // 47473046
*  getUUID(8, 16)  // 098F4D35
* */
function getUUID(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [];
  var i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) {
      uuid[i] = chars[0 | Math.random() * radix];
    }
  } else {
    var r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[i === 19 ? r & 0x3 | 0x8 : r];
      }
    }
  }
  return uuid.join('');
}

//时间格式化 =》 202109071530 -> 2021-09-07 15:30
//第二个参数是确定是否返回时分秒，默认返回时分秒，如果nohour为true，则不返回时分秒
const timeParse = function (time, noHour, splitString) {
  if (!time) {
    return '-';
  }
  splitString = splitString || '-';
  time = time.toString();
  if (noHour) {
    return time.substring(0, 4) + splitString + time.substring(4, 6) + splitString + time.substring(6, 8);
  }
  return time.substring(0, 4) + splitString + time.substring(4, 6) + splitString + time.substring(6, 8) + " " + time.substring(8, 10) + ":" + time.substring(10, 12) + ":" + time.substring(12, 14);
};
//数组乱序
const shuffle = function (array) {
  var j, x, i;
  for (i = array.length; i; i--) {
    j = Math.floor(Math.random() * i);
    x = array[i - 1];
    array[i - 1] = array[j];
    array[j] = x;
  }
  return array;
};

//年月日小于0的时候格式化+0
function formatNumber(number) {
  if (number < 10) {
    return "0" + number;
  } else {
    return number;
  }
}
function deepCopy(currentObj) {
  let newObj = Array.isArray(currentObj) ? [] : {}; //Step#1
  for (let key in currentObj) {
    //Step#2
    let property = currentObj[key];
    if (typeof property === "object") {
      newObj[key] = deepCopy(property); //Step#3
    } else {
      newObj[key] = property; //Step#3
    }
  }

  return newObj;
}

//获取风险的URL路径
const getRiskURL = function (data, result, dictItem) {
  /**
   * data:当前数据
   * result: 档条风险所有数据（风险）
   * type=>
   * 1:举证截图
   * 2:侵权项
   * 3:app地址
   * 4:泄露内容图片地址
   * 5:授权文件
   */
  var url = 'riskresource/' + timeCutOut(result.befRiskDiscoveryTime ? result.befRiskDiscoveryTime : result.discoveryTime) + '/' + result.riskId + '/' + dictItem.key + '/' + data.name;
  if (dictItem.key == 'infringement') {
    //实验室风险处置生成工单或者用户手填创建工单
    if (data.createSrcId == 1) {
      //澳大利亚
      url = 'riskresource/' + timeCutOut(result.befRiskDiscoveryTime ? result.befRiskDiscoveryTime : result.discoveryTime) + '/' + result.riskId + '/' + dictItem.key + '/' + data.name;
    } else {
      //实验室
      url = 'brandresource/' + data.brandId + '/resource/' + data.name;
    }
  }
  return url;
};
//获取工单的URL路径
const getOrderURL = function (data, dictItem) {
  /**
   * type=>
   * 1:举证截图，对应字段名evidence
   * 2:侵权项,对应字段名infringement
   * 3:app地址，对应字段名appPath
   * 4:泄露内容图片地址，对应字段名leakageContent
   * 5:授权文件，对应字段名authorizationFile
   */
  let pathKey = dictItem.key;
  if (dictItem.key == 'appPath') {
    pathKey = 'falseReportApp';
  }
  var url = 'ticketresource/' + timeCutOut(data.cdate) + '/' + data.ticketId + '/' + pathKey + '/' + data.name;
  if (dictItem.key == 'infringement') {
    //实验室风险处置生成工单或者用户手填创建工单
    if (data.createSrcId == 1) {
      url = 'ticketresource/' + timeCutOut(data.cdate) + '/' + data.id + '/' + dictItem.key + '/' + data.name;
    } else {
      url = 'brandresource/' + data.brandId + '/resource/' + data.name;
    }
  }
  return url;
};
const calcTextareaPositionText = function (domRef, currentText) {
  let start = domRef.current.dom.selectionStart;
  let end = domRef.current.dom.selectionEnd;
  let text = domRef.current.dom.value;
  let value;
  if (start === 0 && end === 0) {
    value = currentText + text.slice(end);
  } else {
    value = text.slice(0, start) + currentText + text.slice(end, text.length);
  }
  return value;
};

/*
获取url地址
data:图片数据
type：图片类型
result：当前数据
*/
const getURL = function (tabType, data, dictItem, result, imgSrcName) {
  imgSrcName = imgSrcName || 'name';
  if (tabType == 0) {
    //风险
    let itemData = {};
    if (typeof data != 'string') {
      itemData = {
        name: data[imgSrcName],
        brandId: data.brandId || result.brandId,
        createSrcId: data.createSrcId
      };
    } else {
      itemData = {
        name: data,
        createSrcId: result.createSrcId
      };
    }
    return getRiskURL(itemData, result, dictItem);
  } else {
    //工单
    let itemData = {};
    if (typeof data != 'string') {
      itemData = data;
      itemData.brandId = data.brandId || result.brandId;
      itemData.ticketId = result.ticketId || '';
      itemData.name = data[imgSrcName];
    } else {
      itemData = {
        name: data,
        brandId: result.brandId,
        ticketId: result.ticketId || '',
        createSrcId: result.createSrcId
      };
    }
    if (!itemData.cdate) {
      itemData.cdate = result.cdate;
    }
    return getOrderURL(itemData, dictItem);
  }
};

//删除html中的危险标签
function replaceDangerousHtml(str) {
  if (!str) {
    return '';
  }
  // [CH] 清除掉所有危险标签（包括a）可适当修改
  let text = str.replace(/<(\/?)(a|script|i?frame|style|html|body|title|link|meta|\?|\%)([^>]*?)>/ig, '');
  // [CH] 清除掉所有事件
  text = text.replace(/(<[^>]*)on[a-zA-Z]+\s*=([^>]*>)/ig, '');
  return text;
}

//截取时间
function timeCutOut(time) {
  if (time) {
    var result = time.toString().split("-");
    if (result.length == 1) {
      result = timeParse(time).split("-");
    }
    var year = result[0].substring(2),
      month = result[1];
    return year + month;
  } else {
    return "";
  }
}
const aDownloadFile = function (url, name) {
  const link = document.createElement('a');
  link.style.display = 'none';
  // 设置下载地址
  link.setAttribute('href', url);
  // 设置文件名
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const truncateText = (text, maxLines) => {
  // 将字符串按顿号分割成数组
  const segments = text.split("、");
  // 计算前 maxLines-1 行的总长度
  const maxChars = segments.slice(0, maxLines - 1).join("、").length;
  // 截取前 maxChars 个字符
  let truncatedText = text.substring(0, maxChars);

  // 如果截取后的文本不是原始文本，则添加省略号
  if (truncatedText !== text) {
    truncatedText += "等";
  }
  return truncatedText;
};
const formDownLoadFun = function (url, params) {
  let form = document.createElement('form');
  form.style.display = 'none';
  form.action = url;
  form.method = 'POST';
  form.target = '_blank';
  document.body.appendChild(form);
  // 动态创建input并给value赋值
  for (var key in params) {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  }
  form.submit();
  form.remove();
};
const showMsg = function (result) {
  let _msg = "";
  const lang = localStorage.getItem('arco-lang');
  let languageType = lang ? lang.split('-')[0] : 'zh';
  if (result.msgCode) {
    _msg = language[result.msgCode] ? language[result.msgCode][languageType] : result.msg;
  } else if (result.msgcode) {
    _msg = language[result.msgcode] ? language[result.msgcode][languageType] : result.msg;
  } else if (result.msg) {
    _msg = result.msg;
  } else {
    _msg = result;
  }
  if (result.result === 0) {
    Message.success(_msg);
  } else {
    if (result.msgCode == '058009' || result.msgCode == '059011') {
      Message.warning(_msg);
    } else {
      Message.error(_msg);
    }
  }
};
const sortFun = (list, key) => {
  return list.sort(function (n1, n2) {
    let item1 = new Date(n1[key]).getTime();
    let item2 = new Date(n2[key]).getTime();
    return item2 - item1;
  });
};
//秒转化成 xx h xx m xx s
const secondToTime = value => {
  if (!value) {
    return '00s';
  }
  var result = parseInt(value);
  var h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
  var m = Math.floor(result / 60 % 60) < 10 ? '0' + Math.floor(result / 60 % 60) : Math.floor(result / 60 % 60);
  var s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);
  var res = '';
  if (h !== '00') res += h + 'h ';
  if (m !== '00') res += m + 'm ';
  res += s + 's';
  return res;
};
const timeToDateFun = timestamp => {
  let time = new Date(timestamp);
  let year = time.getFullYear();
  let month = time.getMonth() + 1;
  let date = time.getDate();
  let hours = time.getHours();
  let minute = time.getMinutes();
  let second = time.getSeconds();
  if (month < 10) {
    month = '0' + month;
  }
  if (date < 10) {
    date = '0' + date;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minute < 10) {
    minute = '0' + minute;
  }
  if (second < 10) {
    second = '0' + second;
  }
  return year + '-' + month + '-' + date + ' ' + hours + ':' + minute + ':' + second;
};
const getDateFn = {
  //1、 得到今天、昨天、明天日期
  //dates为数字类型，0代表今日,-1代表昨日，1代表明日，返回yyyy-mm-dd格式字符串，dates不传默认代表今日。
  getDate(dates) {
    var dd = new Date();
    var n = dates || 0;
    dd.setDate(dd.getDate() + n);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    var d = dd.getDate();
    m = m < 10 ? "0" + m : m;
    d = d < 10 ? "0" + d : d;
    var day = y + "-" + m + "-" + d;
    return day;
  },
  //2、得到本周、上周、下周的起始、结束日期
  //type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，dates为数字类型，不传或0代表本周，-1代表上
  getMonday(type, dates) {
    var now = new Date();
    var nowTime = now.getTime();
    var day = now.getDay();
    var longTime = 24 * 60 * 60 * 1000;
    var n = longTime * 7 * (dates || 0);
    if (type == "s") {
      var dd = nowTime - (day - 1) * longTime + n;
    }
    if (type == "e") {
      var dd = nowTime + (7 - day) * longTime + n;
    }
    dd = new Date(dd);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    var d = dd.getDate();
    m = m < 10 ? "0" + m : m;
    d = d < 10 ? "0" + d : d;
    var day = y + "-" + m + "-" + d;
    return day;
  },
  //3、得到本月、上月、下月的起始、结束日期
  //type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，months为数字类型，0代表本月，-1代表上月，1代表下月
  getMonth(type, months) {
    var d = new Date();
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    if (Math.abs(months) > 12) {
      months = months % 12;
    }
    if (months != 0) {
      if (month + months > 12) {
        year++;
        month = (month + months) % 12;
      } else if (month + months < 1) {
        year--;
        month = 12 + month + months;
      } else {
        month = month + months;
      }
    }
    month = month < 10 ? "0" + month : month;
    var firstday = year + "-" + month + "-" + "01";
    var lastday = "";
    if (month == "01" || month == "03" || month == "05" || month == "07" || month == "08" || month == "10" || month == "12") {
      lastday = year + "-" + month + "-" + 31;
    } else if (month == "02") {
      if (year % 4 == 0 && year % 100 != 0 || year % 100 == 0 && year % 400 == 0) {
        lastday = year + "-" + month + "-" + 29;
      } else {
        lastday = year + "-" + month + "-" + 28;
      }
    } else {
      lastday = year + "-" + month + "-" + 30;
    }
    var day = "";
    if (type == "s") {
      day = firstday;
    } else {
      day = lastday;
    }
    return day;
  },
  //4、得到今年、去年、明年的开始、结束日期
  //type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，dates为数字类型，不传或0代表今年，-1代表去年，1代表明年
  getYear(type, dates) {
    var dd = new Date();
    var n = dates || 0;
    var year = dd.getFullYear() + Number(n);
    if (type == "s") {
      var day = year + "-01-01";
    }
    if (type == "e") {
      var day = year + "-12-31";
    }
    if (!type) {
      var day = year + "-01-01/" + year + "-12-31";
    }
    return day;
  },
  //5、得到本学期:
  //"s"代表开始,"e"代表结束，dates为数字类型，2-8月份为下学期
  getTerm(type, dates) {
    let nowYear = new Date().getFullYear();
    let prevYear = new Date().getFullYear() - 1;
    let nextYear = new Date().getFullYear() + 1;
    if (type === 's') {
      if (dates < 2) {
        return prevYear + "-09-01";
      } else if (dates > 8) {
        return nowYear + "-09-01";
      } else {
        return nowYear + "-02-01";
      }
    }
    if (type === 'e') {
      if (dates < 2) {
        return nowYear + "-01-31";
      } else if (dates > 8) {
        return nextYear + "-01-31";
      } else {
        return nowYear + "-08-31";
      }
    }
  }
};

//字符串模糊匹配
function fuzzyMatchingKeyword(keyword, string) {
  return string.indexOf(keyword) > -1;
}

//数据初始化父子关系
function dataRelationshipInit(data, parentId) {
  let parent = [],
    children = [];
  data.map(item => {
    if (item[parentId]) {
      children.push(item);
    } else {
      parent.push(item);
    }
  });
  children.map();
}
export default {
  replaceDangerousHtml,
  formatNumber,
  shuffle,
  dataRelationshipInit,
  getDateFn,
  calcTextareaPositionText,
  formDownLoadFun,
  getUUID,
  aDownloadFile,
  copyFun,
  deepCopy,
  timeToDateFun,
  secondToTime,
  sortFun,
  showMsg,
  getURL,
  truncateText,
  getRiskURL,
  getOrderURL,
  timeCutOut,
  indexOf: indexOf,
  getFieldVal: getFieldVal,
  getStatuName: getStatuName,
  timeParse: timeParse,
  getReadKeyType: getReadKeyType,
  getKeyType: getKeyType,
  fuzzyMatchingKeyword
};