import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [
/*{
    name: 'menu.dashboard',
    key: 'dashboard',
    children: [
        {
            name: 'menu.dashboard.workplace',
            key: 'dashboard/workplace',
        },
        {
            name: 'menu.dashboard.monitor',
            key: 'dashboard/monitor',
            requiredPermissions: [
                {resource: 'menu.dashboard.monitor', actions: ['write']},
            ],
        },
    ],
},*/
//侵权欺诈
{
  name: '侵权欺诈',
  enName: 'Fraud',
  key: 'control/tortFraud',
  type: 1
},
//数据泄露
{
  name: '数据泄露',
  enName: 'Data Leakage',
  key: 'control/dataBreach',
  type: 4
},
//版权盗版
{
  name: '版权盗版',
  enName: 'Copyright Piracy',
  key: 'control/copyrightPiracy',
  type: 16
},
//威胁误报
{
  name: '威胁误报',
  enName: 'False Threat',
  key: 'control/misreport',
  type: 3
},
//暗网
{
  name: '暗网威胁',
  enName: 'Dark Web',
  key: 'control/darkNet',
  type: 15
}, {
  name: '报告详情',
  enName: 'Detail',
  key: 'reportCenter/detail',
  ignore: true
}, {
  name: '报告详情',
  enName: 'CustomDetail',
  key: 'reportCenter/customDetail',
  ignore: true
}, {
  name: '查看报表',
  enName: 'View',
  key: 'reportCenter/watchReportForm',
  ignore: true
}, {
  name: '数字风险报告详情',
  enName: 'pdfDetail',
  key: 'digitalRiskReport/pdfDetail',
  ignore: true
}, {
  name: '审核白名单',
  enName: 'WhiteList',
  key: 'riskDetect/whiteListPage',
  ignore: true
},
/*{
    name: '资产管理',
    enName: 'Asset',
    key: 'assetManage/index',
    type: 15,
},*/
/*{
    name: 'menu.systemLog',
    key: 'SystemManagerOperation/log',
    type: 15,
},*/
//报告中心
/*{
    name: 'menu.reportCenter',
    key: 'reportCenter/index',
},
 //综合查询
{
    name: 'menu.allType',
    key: 'control/allSearch',
    data: {type: 'all'},
},
//工单审核
{
    name: 'menu.ticketExam',
    key: 'control/ticketExam',
    data: {type: 'ticketExam', routeRiskTicketType: 'ticket'},
},
//内部审核
{
    name: 'menu.innerExam',
    key: 'control/innerExam',
    data: {type: 'innerExam', routeRiskTicketType: 'ticket'},
},
//风险探测
{
    name: 'menu.riskDetect',
    key: 'riskDetect',
},*/
//系统管理
/*{
    name: 'menu.SystemManager',
    key: 'SystemManager',
},
//系统管理
{
    name: 'menu.SystemManagerOperation',
    key: 'SystemManagerOperation/Staff',
    children: [
      {//员工管理
        name: 'menu.SystemManagerOperation.staff',
        key: 'SystemManagerOperation/Staff',
      },{//客户管理
        name: 'menu.SystemManagerOperation.customers',
        key: 'SystemManagerOperation/Customers',
      },
    ],
}, 
{
    name: 'menu.ServiceManagement',
    key: 'ServiceManagement',
},*/
{
  name: '基本信息',
  enName: 'BasicInfo',
  key: 'userCenter/basicInfo',
  ignore: true
}, {
  name: '服务条款',
  enName: 'Disclaimer',
  key: 'userCenter/disclaimer',
  ignore: true
}, {
  name: '通知设置',
  enName: 'Msgntific',
  key: 'userCenter/msgntific',
  ignore: true
}, {
  name: '我的账单',
  enName: 'Mybill',
  key: 'userCenter/mybill',
  ignore: true
}
/*{
  name: 'menu.visualization',
  key: 'visualization',
  children: [
    {
      name: 'menu.visualization.dataAnalysis',
      key: 'visualization/data-analysis',
      requiredPermissions: [
        { resource: 'menu.visualization.dataAnalysis', actions: ['read'] },
      ],
    },
    {
      name: 'menu.visualization.multiDimensionDataAnalysis',
      key: 'visualization/multi-dimension-data-analysis',
      requiredPermissions: [
        {
          resource: 'menu.visualization.dataAnalysis',
          actions: ['read', 'write'],
        },
        {
          resource: 'menu.visualization.multiDimensionDataAnalysis',
          actions: ['write'],
        },
      ],
      oneOfPerm: true,
    },
  ],
},
{
  name: 'menu.list',
  key: 'list',
  children: [
    {
      name: 'menu.list.searchTable',
      key: 'list/search-table',
    },
    {
      name: 'menu.list.cardList',
      key: 'list/card',
    },
  ],
},
{
  name: 'menu.form',
  key: 'form',
  children: [
    {
      name: 'menu.form.group',
      key: 'form/group',
      requiredPermissions: [
        { resource: 'menu.form.group', actions: ['read', 'write'] },
      ],
    },
    {
      name: 'menu.form.step',
      key: 'form/step',
      requiredPermissions: [
        { resource: 'menu.form.step', actions: ['read'] },
      ],
    },
  ],
},
{
  name: 'menu.profile',
  key: 'profile',
  children: [
    {
      name: 'menu.profile.basic',
      key: 'profile/basic',
    },
  ],
},
 {
  name: 'menu.result',
  key: 'result',
  children: [
    {
      name: 'menu.result.success',
      key: 'result/success',
      breadcrumb: false,
    },
    {
      name: 'menu.result.error',
      key: 'result/error',
      breadcrumb: false,
    },
  ],
},
{
  name: 'menu.exception',
  key: 'exception',
  children: [
    {
      name: 'menu.exception.403',
      key: 'exception/403',
    },
    {
      name: 'menu.exception.404',
      key: 'exception/404',
    },
    {
      name: 'menu.exception.500',
      key: 'exception/500',
    },
  ],
},
{
  name: 'menu.user',
  key: 'user',
  children: [
    {
      name: 'menu.user.info',
      key: 'user/info',
    },
    {
      name: 'menu.user.setting',
      key: 'user/setting',
    },
  ],
},*/];

export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};
const useRoute = userInfo => {
  const rolePermission = userInfo.rolePermission;
  const loginAs = userInfo.loginAs;
  let defaultRoutes = [...routes];
  if (!userInfo.authorized) {
    defaultRoutes = [];
  }
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, rolePermission);
      }
      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = {
          ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({
          ...route
        });
      }
    }
    return arr;
  };
  const [permissionRoute, setPermissionRoute] = useState(defaultRoutes);
  const [activePermissionFlag, setActivePermissionFlag] = useState(false);
  useEffect(() => {
    let lang = localStorage.getItem('arco-lang');
    lang = lang ? lang.split('-')[0] == 'en' ? 'en' : '' : '';
    let routeList = [];
    let permissionsList = [];
    if (rolePermission && rolePermission.permissions) {
      permissionsList = JSON.parse(JSON.stringify(rolePermission.permissions));
    }
    if (permissionsList) {
      permissionsList.map(item => {
        if (item.isNavbar == 1) {
          if (item.permissionParentId) {
            //如果存在父权限id，则是子菜单
            const permissionIndex = routeList.findIndex(childItem => childItem.permissionId == item.permissionParentId);
            if (permissionIndex > -1) {
              routeList[permissionIndex].children.push({
                name: item.permissionDisplayName,
                enName: item.permissionDisplayNameEn,
                key: item.permissionPath,
                permissionId: item.permissionId
              });
            } else {
              const index = permissionsList.findIndex(childItem => childItem.permissionId == item.permissionParentId);
              routeList.push({
                name: permissionsList[index].permissionDisplayName,
                enName: permissionsList[index].permissionDisplayNameEn,
                key: permissionsList[index].permissionPath,
                permissionId: permissionsList[index].permissionId,
                children: [{
                  name: item.permissionDisplayName,
                  enName: item.permissionDisplayNameEn,
                  key: item.permissionPath,
                  permissionId: item.permissionId
                }]
              });
              permissionsList.splice(index, 1);
            }
          } else {
            routeList.push({
              name: item.permissionDisplayName,
              enName: item.permissionDisplayNameEn,
              key: item.permissionPath,
              permissionId: item.permissionId,
              children: []
            });
          }
        }
      });
    }
    const systemIndex = routeList.findIndex(item => item.key == 'SystemManagerOperation');
    if (systemIndex > -1 && !routeList[systemIndex].children.length) {
      routeList.splice(systemIndex, 1);
    }
    if (routeList[0] && routeList[0].key == 'dashboard/riskPortal') {
      const riskPortalRoute = {
        ...routeList[0]
      };
      routeList.splice(0, 1);
      routeList = [riskPortalRoute, ...defaultRoutes, ...routeList];
    } else {
      routeList = [...defaultRoutes, ...routeList];
    }
    if (loginAs == 'Employee') {
      routeList.unshift({
        name: '工作台',
        enName: 'Workplace',
        key: 'dashboard/workPortal'
      });
    }
    const newRoutes = filterRoute(routeList);
    setPermissionRoute(newRoutes);
    if (loginAs) {
      setActivePermissionFlag(true);
    }
  }, [JSON.stringify(rolePermission)]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute, activePermissionFlag];
};
export default useRoute;