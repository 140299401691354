const reportI18n = {
  'en-US': {
    'dict.median.time': 'Median data processing time',
    'dict.average.time': 'Average processing time',
    'dict.fastest.time': 'Fastest completion time',
    'dict.slowest.time': 'Slowest completion time',
    'dict.monitored.pages': 'Monitored Pages',
    'dict.detected.risks': 'Detected Risks',
    'dict.potential.risks': 'Potential',
    'dict.submitted.risks': 'Submitted',
    'dict.manually.submitted': 'Manually Submitted'
  },
  'zh-CN': {
    'dict.median.time': '处置中位时长',
    'dict.average.time': '平均处置时长',
    'dict.fastest.time': '最快完成时长',
    'dict.slowest.time': '最慢完成时长',
    'dict.monitored.pages': '累计监测风险',
    'dict.detected.risks': '累计发现风险',
    'dict.potential.risks': '潜在风险',
    'dict.submitted.risks': '已提交处置风险',
    'dict.manually.submitted': '手工创建工单'
  }
};
const systemManagerI18n = {
  'en-US': {
    'dict.authority.management': 'Authorization',
    'dict.select.role': 'Select role',
    'dict.default.role': 'Default',
    'dict.custom.role': 'Custom',
    'required.select.role': 'Please select role',
    'dict.module.permissions': 'Configuration',
    'dict.data.Inheritance': 'Data Succession',
    'dict.add.data.access': 'Add an account to inherit data access',
    'dict.accounts.authorized': 'Accounts authorized',
    'dict.review.authority': 'View',
    'dict.edit.authority': 'Edit',
    'dict.edit.role': 'Edit',
    'dict.create.role': 'Create Role',
    'dict.type.role.name': 'Enter role name',
    'dict.role.name': 'Role',
    'dict.list.number': 'No.',
    'dict.individual.account': 'Unassociated',
    'dict.entity.account': 'Associated',
    'dict.monitoring.service': 'Monitoring Service',
    'dict.takedown.service': 'Takedown Service',
    'dict.authorization.service': 'Authorization',
    'dict.brand.configuration': 'Brand configuration',
    'dict.acomplished': 'Acomplished',
    'dict.configuration.saved': 'Configuration saved',
    'dict.last.step': 'Previous',
    'dict.next.step': 'Next',
    'dict.choose.brand.service': 'Please choose brand service',
    'dict.enter.brand': 'Enter brand',
    'dict.setting.role': 'Select Role',
    'dict.role,management': 'Role',
    'dict.operation.time': 'Time',
    'dict.login.ip': 'Login IP',
    'dict.email': 'E-mail',
    'dict.phone': 'Cell Phone',
    'dict.operation.date': 'Date',
    'dict.post': 'Post',
    'dict.staff.name': 'Staff',
    'dict.upload.successful': 'Upload successful',
    'dict.unlimited.usage': 'Unlimited Usage',
    'dict.select.heir': 'Please select the heir',
    'dict.all.brand.configuration': 'Brand Configuration',
    'dict.invite': 'Invite',
    'dict.between.numbers': 'Please enter natural numbers between 0-99999.',
    'dict.enter.takedown.times': 'Please enter takedown times',
    'dict.invite.link': 'Invite by link',
    'dict.invite.account': 'Invite by account',
    'dict.invite.send': 'Send',
    'dict.enter.reason': 'Please enter reason',
    'dict.select.role.invite': 'Please select role to create invite link.',
    'dict.enter.email.invite.link': 'Enter E-mail address to receive invite link.',
    'dict.enter.email': 'Please enter email',
    'dict.email.error': 'Incorrect email format',
    'dict.copy.link': 'Copy link',
    'dict.ticket.deal.count': 'Takedown times allocation',
    'dict.ticket.most.deal.count': 'Maximum times of takedown per month'
  },
  'zh-CN': {
    'dict.authority.management': '权限配置',
    'dict.select.role': '请选择角色',
    'dict.default.role': '默认角色',
    'dict.custom.role': '自定义角色',
    'required.select.role': '请选择角色',
    'dict.module.permissions': '设置模块权限',
    'dict.data.Inheritance': '数据继承',
    'dict.add.data.access': '添加数据继承',
    'dict.accounts.authorized': '已继承数据权限',
    'dict.review.authority': '查看权限',
    'dict.edit.authority': '修改权限',
    'dict.edit.role': '修改角色',
    'dict.create.role': '创建角色',
    'dict.type.role.name': '请输入角色名称',
    'dict.role.name': '角色名称',
    'dict.list.number': '序号',
    'dict.individual.account': '未关联',
    'dict.entity.account': '已关联',
    'dict.monitoring.service': '监控服务',
    'dict.takedown.service': '处置服务',
    'dict.authorization.service': '授权服务',
    'dict.brand.configuration': '品牌配置',
    'dict.acomplished': '完成',
    'dict.configuration.saved': '已完成权限配置',
    'dict.last.step': '上一步',
    'dict.next.step': '下一步',
    'dict.choose.brand.service': '请选择品牌服务',
    'dict.enter.brand': '请输入品牌',
    'dict.setting.role': '设置角色',
    'dict.role,management': '角色管理',
    'dict.operation.time': '操作时间',
    'dict.login.ip': '登录IP',
    'dict.email': '邮箱',
    'dict.phone': '手机号',
    'dict.operation.date': '操作日期',
    'dict.post': '岗位',
    'dict.staff.name': '员工姓名',
    'dict.upload.successful': '上传成功',
    'dict.unlimited.usage': '不限次',
    'dict.select.heir': '请选择被继承人',
    'dict.all.brand.configuration': '全局配置',
    'dict.invite': '邀请加入',
    'dict.between.numbers': '请输入0-99999之间的整数',
    'dict.enter.takedown.times': '请输入处置次数',
    'dict.invite.link': '通过链接邀请',
    'dict.invite.account': '通过账号邀请',
    'dict.invite.send': '发送邀请',
    'dict.enter.reason': '填写理由',
    'dict.select.role.invite': '请选择角色生成链接邀请',
    'dict.enter.email.invite.link': '输入邮箱账号即可发送邀请链接',
    'dict.enter.email': '请输入邮箱地址',
    'dict.email.error': '邮箱格式不正确',
    'dict.copy.link': '复制链接',
    'dict.ticket.deal.count': '工单处置次数分配',
    'dict.ticket.most.deal.count': '每月最多处置工单次数'
  }
};
const detailDictI18n = {
  'en-US': {
    'dict.infringer.item': 'Infringed Item',
    'dict.screenshots': 'Screenshots',
    'dict.start.time': 'Start',
    'dict.end.time': 'End',
    'dict.create.time': 'Creation Time',
    'dict.update.time': 'Update Time',
    'dict.discovery.time': 'Discovery Time',
    'dict.last.discovery.time': 'Update Time',
    'dict.check.time': 'Discovery Time',
    'dict.ip.address': 'IP',
    'dict.domain.registrar': 'Domain name registrar',
    'dict.location': 'Location',
    'dict.asn': 'asn',
    'dict.isp': 'ISP',
    'dict.remark': 'Notes',
    'dict.placeholder.remark': 'Please enter notes',
    'dict.placeholder.reason': 'Please enter reason',
    'dict.brandName': 'Brand Name',
    'dict.brand': 'Brand',
    'dict.seleted': 'Seleted',
    'dict.select.all.brand': 'All Brands',
    'dict.deselect.all.brand': 'Deselect All',
    'dict.evidence': 'Evidence',
    'dict.enter.infringing.website': 'Please enter infringing website',
    'dict.enter.url': 'Please enter url',
    'dict.infringing.website': 'Infringing website',
    'dict.upload.length.limit': 'Upload 8 photos maximum.Each must be less than 5M',
    'dict.upload.size.limit': 'Must be less than ',
    'dict.button.upload': 'Upload screenshots',
    'dict.button.re-upload': 'Re-upload',
    'dict.upload.limit': 'Please upload graphic evidence',
    'dict.upload.leaked.limit': 'Please upload screenshots',
    'dict.url.limit': 'The URL format is incorrect. Please enter http (s)://',
    'dict.supplementary.information': 'Additional Info',
    'dict.reason': 'Reason',
    'dict.ignore.reason': 'Ignore reason',
    'dict.enter.ignore.reason': 'Please enter ignore reason',
    'dict.primary.data': 'Primary data',
    'dict.risk.level': 'Risk Level',
    'dict.website': 'Website',
    'dict.ip': 'IP',
    'dict.social.media': 'Social Media',
    'dict.platform': 'Platform',
    'dict.account': 'Account',
    'dict.account.id': 'Account ID',
    'dict.social.content': 'Content',
    'dict.description': 'Description',
    'dict.placeholder.description': 'Please enter description',
    'dict.select.platform': 'Please choose name of platform',
    'dict.enter.platform': 'Please enter name of platform',
    'dict.input.account': 'Please enter account name',
    'dict.input.account.id': 'Please enter account ID',
    'dict.account.account.id': 'Account & Id',
    'dict.describe.infringement.behavior': 'Please describe infringement behavior',
    'dict.app': 'APP',
    'dict.app.link': 'APP link',
    'dict.placeholder.app.link': 'Please enter APP link',
    'dict.publish.time': 'Publish time',
    'dict.infringement.title': 'Infringement Title',
    'dict.placeholder.infringement.title': 'Please enter infringement title',
    'dict.placeholder.false.positives.website': 'Please input false threat website',
    'dict.hitting.position': 'Position of Keywords',
    'dict.hitting.keywords': 'Keywords',
    'dict.content': 'Content',
    'dict.false.website': 'Website',
    'dict.false.app': 'APP',
    'dict.source': 'Source',
    'dict.other.source': 'Other source',
    'dict.enter.other.source': 'Please enter the information of other source',
    'dict.positive.manufacturer': 'Positive manufacturer',
    'dict.please.enter.positive.manufacturer': 'Please enter positive manufacturer',
    'dict.app.name': 'APP name',
    'dict.engine.information': 'Engine information',
    'dict.engine.name': 'Engine name',
    'dict.placeholder.engine.name': 'Please enter engine name',
    'dict.engine.version': 'Engine version',
    'dict.placeholder.engine.version': 'Please enter engine version',
    'dict.inspection.result': 'Inspection result',
    'dict.placeholder.enter.inspection.result': 'Please enter inspection result',
    'dict.auto.after.upload': 'Automatically displayed after file upload',
    'dict.placeholder.upload.app': 'Please upload the app reported false alarm',
    'dict.placeholder.leaked.content': 'Please enter the leaked content',
    'dict.placeholder.leaked.url': 'Please enter the leaked url',
    'dict.upload.file.app.fomat': 'Upload file in app fomat',
    'dict.leaked.content': 'Leaked Content',
    'dict.title': 'Title',
    'dict.placeholder.title': 'Please enter title',
    'dict.uploader': 'Uploader',
    'dict.upload': 'Upload',
    'dict.placeholder.uploader': 'Please enter uploader',
    'dict.auth.file': 'Authorization File',
    'dict.leaked.source': 'Leakage source',
    'dict.placeholder.leaked.source': 'Please enter source of data leakage',
    'dict.piracy.evidence': 'Piracy evidence',
    'dict.placeholder.website': 'Please enter website',
    'dict.quantity.unit': 'Quantity unit',
    'dict.placeholder.quantity.unit': 'Please enter quantity unit',
    'dict.correlation': 'Correlation',
    'dict.select.correlation': 'Please select correlation',
    'dict.dark.leakage.channel': 'Leakage channel',
    'dict.select.dark.leakage.channel': 'Please select Leakage channel',
    'dict.dark.risk.type': 'Risk types',
    'dict.select.dark.risk.type': 'Please select risk types',
    'dict.dark.samples': 'Samples',
    'dict.select.dark.samples': 'Please select samples',
    'dict.dark.group': 'Telegram Group Name',
    'dict.dark.account': 'Account',
    'dict.upload.time': 'Upload time',
    'dict.dark.platform': 'Darkweb platform',
    'dict.select.reason': 'Please select reason',
    'dict.submit.time': 'Submit Time',
    'dict.pass.time': 'Pass time',
    'dict.ignore.time': 'Ignore Time',
    'dict.process.duration': 'Process duration',
    'dict.finish.time': 'Finish Time',
    'dict.pending.time': 'Pending time',
    'dict.rejection.time': 'Rejection time',
    'dict.cancel.time': 'Cancel time',
    'dict.close.time': 'Closing time',
    'dict.risk.detail': 'Risk Detail',
    'dict.ticket.detail': 'Ticket Detail',
    'dict.ignored.by': 'Ignored by',
    'dict.comments': 'Comments',
    'dict.operational.record': 'Operational Record',
    'dict.operational': 'Operation',
    'dict.tag': 'Tag',
    'dict.tag.exists': 'Tag already exists',
    'dict.enter.tag': 'Please enter tag',
    'dict.tag.add': 'Add Tag',
    'dict.tag.admin': 'Tag Management',
    'dict.tag.preset': 'Preset Tag',
    'dict.button.reject': 'Reject',
    'dict.button.pass': 'Pass',
    'dict.edit': 'Edit',
    'dict.button.edit': 'Edit',
    'dict.button.website.monitoring': 'Website Monitoring',
    'dict.domain.global': 'Domain availability verification from global nodes',
    'dict.button.edit.note': 'Edit Note',
    'dict.button.watch.result': 'Result',
    'dict.button.watch': 'View',
    'dict.button.publish': 'Publish',
    'dict.button.delete': 'Delete',
    'dict.button.ignore': 'Ignore',
    'dict.button.process': 'Process',
    'dict.button.quickly.create.ticket': 'Quickly Create Ticket',
    'dict.button.reopen.ticket': 'Re-open Ticket',
    'dict.button.tag.management': 'Tag Management',
    'dict.button.status.cancel': 'Cancel',
    'dict.button.change.status': 'Change Status',
    'dict.button.download.confirm.letter': 'Download Confirm Letter',
    'dict.button.download': 'Download',
    'dict.button.upload.confirm.letter': 'Upload Confirm Letter',
    'dict.button.vitality.check': 'Vitality Check',
    'dict.button.processing': 'Processing',
    'dict.button.expired.re-check': 'Expired, please re-check',
    'dict.button.failed.check': 'Checked failed，please check again',
    'dict.button.send': 'Send',
    'dict.button.reply': 'Reply',
    'dict.button.retract': 'Retract',
    'dict.button.confirm': 'OK',
    'dict.button.submit': 'Submit',
    'dict.button.save': 'Save',
    'dict.button.reset': 'Reset',
    'dict.button.cancel': 'Cancel',
    'dict.button.back': 'Back',
    'dict.button.export.data': 'Export Data',
    'dict.button.white.list': 'White List',
    'dict.enter.comment': 'Please enter comment',
    'dict.placeholder.comment': 'Write a comment...',
    'dict.isDelete.comment': 'Do you want to delete the comment',
    'dict.comment.length.limit.140': 'Comments must be within 140 words',
    'dict.yes': 'Yes',
    'dict.no': 'No',
    'dict.risk.level.high': 'High',
    'dict.risk.level.middle': 'Medium',
    'dict.risk.level.low': 'Low',
    'dict.info.center': 'Notification',
    'dict.comment.info': 'Comments',
    'dict.system.info': 'System',
    'dict.service.info': 'Service',
    'dict.business.info': 'Business',
    'dict.data.info': 'Data',
    'dict.message.unread': 'Unread',
    'dict.message.read': 'Read',
    'dict.message.all.read': 'Mark all as read',
    'dict.no.comments': 'No comments',
    'dict.no.messages': 'No messages',
    'dict.no.data': 'No Data',
    'dict.last.3days': 'Last 3 days',
    'dict.last.week': 'Last week',
    'dict.earlier': 'Earlier',
    'dict.all.company': 'All Customers',
    'dict.all': 'All',
    'dict.select.all': 'All',
    'dict.select.all.company': 'All Customers',
    'dict.deselect.all': 'Deselect all',
    'dict.advanced.filter': 'Advanced Filter',
    'dict.special.customers': 'Customers',
    'dict.pending': 'Pending',
    'dict.ignored': 'Ignored',
    'dict.finished': 'Finished',
    'dict.passed': 'Passed',
    'dict.search.brand': 'Search Brand',
    'dict.dark.web.info': 'Dark Web info',
    'dict.more.info': 'More info',
    'dict.custom': 'Custom',
    'dict.dark.screenshot.info': 'Screenshot contains sensitive information. For further details please contact your Customer Success Manager.',
    'dict.add': 'Add',
    'dict.ignore.this.risk': 'Are you sure you want to ignore this risk?',
    'dict.report.published': 'Published',
    'dict.report.unpublished': 'Unpublished',
    'dict.report.name': 'Report Name',
    'dict.report.template': 'Report Template',
    'dict.statistical.period': 'Statistical Period',
    'dict.report.configuration': 'Report Configuration',
    'dict.service.type': 'Service Type',
    'dict.ticket.status': 'Ticket Status',
    'dict.report.customer': 'Customer',
    'dict.upload.file': 'Upload',
    'dict.file.upload': 'File Upload',
    'dict.report.upload.limit': 'Doc and PDF only and no more than 50MB',
    'dict.report.upload.zip.rar.limit': 'Zip、Rar、Exe、Apk、Ipa、Deb、Txt、Pdf、Png、Jpg only and no more than ',
    'dict.placeholder.report.name': 'Please enter report name',
    'dict.contracted.customers': 'Signed',
    'dict.poc': 'POC',
    'dict.potential.customers': 'Potential',
    'dict.supplementary.remark': 'Supplementary Remark',
    'dict.enable': 'Enable',
    'dict.deactivate': 'Deactivate',
    'dict.deactivation.operator': 'Deactivation operator',
    'dict.deactivation.time': 'Deactivation time',
    'dict.activate': 'Account Status',
    'dict.sales': 'Sales',
    'dict.presales': 'Presales',
    'dict.customer.success': 'CSM',
    'dict.select.sales': 'Sales',
    'dict.required.sales': 'Please select Sales',
    'dict.select.date': 'Select date',
    'dict.customer.name': 'Customer',
    'dict.select': 'Select',
    'dict.assigned.each.position': '5 accounts at most assigned for each position',
    'dict.confirm.delete.action': 'Please confirm the delete action',
    'dict.one.option.at.least': 'Please one option at least',
    'dict.click.here': 'Click here',
    'dict.change.status': 'Change Status',
    'dict.enter.brandName': 'Add mapping brand',
    'dict.mapping.brand': 'Mapping Brand',
    'dict.is.required': 'is required',
    'dict.create.ticket': 'Create Ticket',
    'dict.is.batch.risk': 'Are you sure of processing selected risks?',
    'dict.is.deal.risk': 'Do you confirm to dispose this risk?',
    'dict.confirm.ignore.risk': 'Please confirm ignore current risk?',
    'dict.confirm.batch.ignore.risk': 'Are sure to ignore the choosen risks?',
    'dict.confirm.batch.deal.risk': 'Are sure to confirm the choosen risks?',
    'control.batch.exit': 'Exit',
    'control.batch.confirm': 'Confirm',
    'control.batch.ignore': 'Ignore',
    'control.batch.deal': 'Process',
    'control.copy.success': 'Replicating Success',
    'control.copy': 'Copy',
    'dict.masked': 'Masked',
    'control.starting.reprocess': 'Are you sure of starting reprocess?',
    'control.account.or.id': 'Account name or account ID',
    'control.with.or.without.screenshots': 'With or without screenshots?',
    'dict.select.ticket.status': 'Please select the status of ticket',
    'dict.enter.code': 'Enter Code',
    'dict.required.code': 'Please enter code',
    'dict.enter.username': 'Please enter email or account',
    'dict.enter.password': 'Please enter password',
    'dict.select.finish.time': 'Please select the finish time',
    'dict.admin.upload.trademark': 'Ticket creation failed for the lack of trademark certificate. Please contack service certer.',
    'dict.extend': 'Extend',
    'dict.fold': 'Fold',
    'dict.re-open': 'Re-open',
    'dict.risk.monitored': 'Risk Existed',
    'dict.expired': 'Expired',
    'dict.mine.brands': 'My brands'
  },
  'zh-CN': {
    'dict.infringer.item': '侵权项',
    'dict.screenshots': '举证截图',
    'dict.start.time': '开始时间',
    'dict.end.time': '结束时间',
    'dict.create.time': '创建时间',
    'dict.update.time': '更新时间',
    'dict.discovery.time': '发现时间',
    'dict.last.discovery.time': '最新发现时间',
    'dict.check.time': '检测时间',
    'dict.ip.address': '解析地址',
    'dict.domain.registrar': '域名注册商',
    'dict.location': '地理位置',
    'dict.asn': 'asn',
    'dict.isp': '运营商',
    'dict.remark': '备注',
    'dict.placeholder.remark': '请输入备注',
    'dict.placeholder.reason': '请输入原因',
    'dict.brandName': '品牌名称',
    'dict.brand': '品牌',
    'dict.seleted': '已选',
    'dict.select.all.brand': '全选品牌',
    'dict.deselect.all.brand': '取消全选',
    'dict.evidence': '侵权证据',
    'dict.enter.infringing.website': '请填写侵权网址',
    'dict.enter.url': '请填写URL',
    'dict.infringing.website': '侵权网址',
    'dict.upload.length.limit': '最多可上传8张图片，每张大小不超过5MB',
    'dict.upload.size.limit': '大小不超过',
    'dict.button.upload': '上传举证截图',
    'dict.button.re-upload': '重新上传',
    'dict.upload.limit': '请上传举证截图',
    'dict.upload.leaked.limit': '请上传举证截图',
    'dict.url.limit': 'url格式不正确，请输入http(s)://',
    'dict.supplementary.information': '补充材料',
    'dict.reason': '原因',
    'dict.ignore.reason': '忽略原因',
    'dict.enter.ignore.reason': '请输入忽略原因',
    'dict.primary.data': '原始数据',
    'dict.risk.level': '风险等级',
    'dict.website': '网站',
    'dict.ip': '解析地址',
    'dict.social.media': '社交媒体',
    'dict.platform': '平台',
    'dict.account': '欺诈账号',
    'dict.account.id': '账号ID',
    'dict.social.content': '欺诈内容',
    'dict.description': '描述',
    'dict.placeholder.description': '请填写描述',
    'dict.select.platform': '请选择平台',
    'dict.enter.platform': '请输入平台名称',
    'dict.input.account': '请填写账号名称',
    'dict.input.account.id': '请填写账号ID',
    'dict.account.account.id': '欺诈账号 & ID',
    'dict.describe.infringement.behavior': '请描述本账号的侵权或恶意行为',
    'dict.app': 'APP',
    'dict.app.link': 'APP链接',
    'dict.placeholder.app.link': '请填写app链接',
    'dict.publish.time': '发布时间',
    'dict.infringement.title': '侵权标题',
    'dict.placeholder.infringement.title': '请填写侵权标题',
    'dict.placeholder.false.positives.website': '请输入误报网站',
    'dict.hitting.position': '命中位置',
    'dict.hitting.keywords': '命中关键词',
    'dict.content': '内容',
    'dict.false.website': '误报网站',
    'dict.false.app': '误报APP',
    'dict.source': '误报源',
    'dict.other.source': '其他误报源',
    'dict.enter.other.source': '请输入其他误报源',
    'dict.positive.manufacturer': '误报厂商',
    'dict.please.enter.positive.manufacturer': '请输入误报厂商',
    'dict.app.name': '应用名称',
    'dict.engine.information': '引擎信息',
    'dict.engine.name': '引擎名称',
    'dict.placeholder.engine.name': '请填写引擎名称',
    'dict.engine.version': '引擎版本',
    'dict.placeholder.engine.version': '请填写引擎版本',
    'dict.inspection.result': '检测结果',
    'dict.placeholder.enter.inspection.result': '请输入检测结果',
    'dict.auto.after.upload': '上传文件后自动带入',
    'dict.placeholder.upload.app': '请上传误报应用',
    'dict.placeholder.leaked.content': '请填写泄露内容',
    'dict.placeholder.leaked.url': '请填写泄露地址',
    'dict.upload.file.app.fomat': '请上传app格式文件',
    'dict.leaked.content': '泄露内容',
    'dict.title': '标题',
    'dict.placeholder.title': '请填写标题',
    'dict.uploader': '上传人',
    'dict.upload': '上传',
    'dict.placeholder.uploader': '请填写上传人',
    'dict.auth.file': '授权文件',
    'dict.leaked.source': '泄露源',
    'dict.placeholder.leaked.source': '请填写泄露地址',
    'dict.piracy.evidence': '盗版证据',
    'dict.placeholder.website': '请填写网址',
    'dict.quantity.unit': '数据量级',
    'dict.placeholder.quantity.unit': '请填写数据量级',
    'dict.correlation': '品牌关联性',
    'dict.select.correlation': '请选择品牌关联性',
    'dict.dark.leakage.channel': '泄露渠道',
    'dict.select.dark.leakage.channel': '请选择泄露渠道',
    'dict.dark.risk.type': '风险信息类型',
    'dict.select.dark.risk.type': '请选择风险信息类型',
    'dict.dark.samples': '是否存在数据样本',
    'dict.select.dark.samples': '请选择数据样本',
    'dict.dark.group': 'TG群组名称',
    'dict.dark.account': '上传者账号',
    'dict.upload.time': '上传时间',
    'dict.dark.platform': '暗网平台名称',
    'dict.select.reason': '请选择原因',
    'dict.submit.time': '提交时间',
    'dict.pass.time': '通过时间',
    'dict.ignore.time': '忽略时间',
    'dict.process.duration': '处理时长',
    'dict.finish.time': '完成时间',
    'dict.pending.time': '暂停时间',
    'dict.rejection.time': '驳回时间',
    'dict.cancel.time': '撤销时间',
    'dict.close.time': '关闭时间',
    'dict.risk.detail': '风险详情',
    'dict.ticket.detail': '工单详情',
    'dict.ignored.by': '忽略人',
    'dict.comments': '评论',
    'dict.operational.record': '操作记录',
    'dict.operational': '操作',
    'dict.tag': '标签',
    'dict.tag.exists': '标签已存在',
    'dict.enter.tag': '请输入标签 ',
    'dict.tag.add': '添加标签',
    'dict.tag.admin': '标签管理',
    'dict.tag.preset': '预设标签',
    'dict.button.reject': '驳回',
    'dict.button.pass': '通过',
    'dict.edit': '修改',
    'dict.button.edit': '编辑',
    'dict.button.website.monitoring': '网站监控',
    'dict.domain.global': '全球节点验活',
    'dict.button.edit.note': '编辑备注',
    'dict.button.watch.result': '查看结果',
    'dict.button.watch': '查看',
    'dict.button.publish': '发布',
    'dict.button.delete': '删除',
    'dict.button.ignore': '忽略',
    'dict.button.process': '处置',
    'dict.button.quickly.create.ticket': '快速创建工单',
    'dict.button.reopen.ticket': '重开工单',
    'dict.button.tag.management': '标记管理',
    'dict.button.status.cancel': '撤销',
    'dict.button.change.status': '改变工单状态',
    'dict.button.download.confirm.letter': '下载确认单',
    'dict.button.download': '下载',
    'dict.button.upload.confirm.letter': '上传确认单',
    'dict.button.vitality.check': '一键验活',
    'dict.button.processing': '验活中',
    'dict.button.expired.re-check': '已过期，请重新验活',
    'dict.button.failed.check': '验活失败，请重新验活',
    'dict.button.send': '发送',
    'dict.button.reply': '回复',
    'dict.button.retract': '收起',
    'dict.button.confirm': '确认',
    'dict.button.submit': '提交',
    'dict.button.save': '保存',
    'dict.button.reset': '重置',
    'dict.button.cancel': '取消',
    'dict.button.back': '返回',
    'dict.button.export.data': '导出数据',
    'dict.button.white.list': '审核白名单',
    'dict.enter.comment': '请输入评论',
    'dict.placeholder.comment': '请输入评论',
    'dict.isDelete.comment': '是否删除当前评论',
    'dict.comment.length.limit.140': '评论必须140字以内',
    'dict.yes': '是',
    'dict.no': '否',
    'dict.risk.level.high': '高风险',
    'dict.risk.level.middle': '中风险',
    'dict.risk.level.low': '低风险',
    'dict.info.center': '消息中心',
    'dict.comment.info': '评论消息',
    'dict.system.info': '系统消息',
    'dict.service.info': '服务通知',
    'dict.business.info': '业务通知',
    'dict.data.info': '数据通知',
    'dict.message.unread': '未读',
    'dict.message.read': '已读',
    'dict.message.all.read': '全部已读',
    'dict.no.comments': '暂无评论',
    'dict.no.messages': '暂无消息',
    'dict.no.data': '暂无数据',
    'dict.last.3days': '近三天',
    'dict.last.week': '近一周',
    'dict.earlier': '更早',
    'dict.all.company': '全部客户',
    'dict.all': '全部',
    'dict.select.all': '全选',
    'dict.select.all.company': '全选客户',
    'dict.deselect.all': '取消全选',
    'dict.advanced.filter': '高级筛选',
    'dict.special.customers': '指定客户',
    'dict.pending': '待处理',
    'dict.ignored': '已忽略',
    'dict.finished': '已完成',
    'dict.passed': '已通过',
    'dict.search.brand': '请搜索品牌',
    'dict.dark.web.info': '暗网信息',
    'dict.more.info': '更多信息',
    'dict.custom': '自定义',
    'dict.dark.screenshot.info': '截图包含敏感信息，欲了解进一步详情，请联系专属客户成功经理',
    'dict.add': '添加',
    'dict.ignore.this.risk': '是否确认忽略此条风险？',
    'dict.report.published': '已发布',
    'dict.report.unpublished': '未发布',
    'dict.report.name': '报告名称',
    'dict.report.template': '报告模板',
    'dict.statistical.period': '统计周期',
    'dict.report.configuration': '统计指标',
    'dict.service.type': '服务类型',
    'dict.ticket.status': '工单状态',
    'dict.report.customer': '对应客户',
    'dict.upload.file': '上传文件',
    'dict.file.upload': '文件上传',
    'dict.report.upload.limit': '仅支持 DOC、PDF格式，大小不超过50MB',
    'dict.report.upload.zip.rar.limit': '支持 zip、rar、exe、apk、ipa、deb、txt、pdf、png、jpg格式，大小不超过',
    'dict.placeholder.report.name': '请输入报告标题',
    'dict.contracted.customers': '已签约',
    'dict.poc': 'POC',
    'dict.potential.customers': '新发现',
    'dict.supplementary.remark': '补充说明',
    'dict.enable': '启用',
    'dict.deactivate': '停用',
    'dict.deactivation.operator': '停用人',
    'dict.deactivation.time': '停用时间',
    'dict.activate': '是否启用',
    'dict.sales': '销售',
    'dict.presales': '售前',
    'dict.customer.success': '客户成功经理',
    'dict.select.sales': '请选择销售',
    'dict.required.sales': '请选择销售',
    'dict.select.date': '选择日期',
    'dict.customer.name': '客户名称',
    'dict.select': '请选择',
    'dict.assigned.each.position': '每个岗位最多分配5个员工',
    'dict.confirm.delete.action': '是否删除当前数据',
    'dict.one.option.at.least': '请至少填写1项',
    'dict.click.here': '点击此处',
    'dict.change.status': '更改状态',
    'dict.enter.brandName': '请输入映射品牌名称',
    'dict.mapping.brand': '映射品牌',
    'dict.is.required': '为必填项',
    'dict.create.ticket': '创建工单',
    'dict.is.batch.risk': '是否批量处置选中的风险',
    'dict.is.deal.risk': '是否处置此条风险',
    'dict.confirm.ignore.risk': '是否忽略当前风险？',
    'dict.confirm.batch.deal.risk': '是否批量确认已选中风险？',
    'dict.confirm.batch.ignore.risk': '是否批量忽略已选中风险?',
    'control.batch.exit': '退出批量',
    'control.batch.confirm': '批量确认',
    'control.batch.ignore': '批量忽略',
    'control.batch.deal': '批量处置',
    'control.copy.success': '复制成功',
    'control.copy': '复制',
    'dict.masked': '已脱敏',
    'control.starting.reprocess': '确定发起重新处置操作？',
    'control.account.or.id': '欺诈账号或ID至少填一项',
    'control.with.or.without.screenshots': '选择有无图片',
    'dict.select.ticket.status': '请选择工单状态',
    'dict.enter.code': '请输入验证码',
    'dict.required.code': '请输入验证码',
    'dict.enter.username': '请输入邮箱或账号',
    'dict.enter.password': '请输入密码',
    'dict.select.finish.time': '请选择完成时间',
    'dict.admin.upload.trademark': '请联系管理员上传注册商标，以完成工单的创建',
    'dict.extend': '展开',
    'dict.fold': '收起',
    'dict.re-open': '重开',
    'dict.risk.monitored': '风险已监测',
    'dict.expired': '过期',
    'dict.mine.brands': '与我相关'
  }
};
const serviceTypeI18n = {
  'en-US': {
    'phishing.brand': 'Fraud',
    'false.positive': 'False Threat',
    'data.leakage': 'Data Leakage',
    'phishing.brand.abuse.website': 'Fraud (Website)',
    'phishing.brand.abuse.social': 'Fraud (Social Media)',
    'phishing.brand.abuse.app': 'Fraud (APP)',
    'phishing.brand.abuse.content': 'Fraud (Content)',
    'false.positive.website': 'False Threat (Website)',
    'false.positive.app': 'False Threat (APP)',
    'data.leakage.doc': 'Data Leakage (Doc)',
    'data.leakage.code': 'Data Leakage (Code)',
    'copyright.piracy': 'Copyright Piracy',
    'dark.web': 'Dark Web',
    'dark.web.exam': 'Dark Web'
  },
  'zh-CN': {
    'phishing.brand': '侵权欺诈',
    'false.positive': '威胁误报',
    'data.leakage': '数据泄露',
    'phishing.brand.abuse.website': '侵权欺诈(网站)',
    'phishing.brand.abuse.social': '侵权欺诈(社媒)',
    'phishing.brand.abuse.app': '侵权欺诈(APP)',
    'phishing.brand.abuse.content': '侵权欺诈(内容)',
    'false.positive.website': '威胁误报(网站)',
    'false.positive.app': '威胁误报(APP)',
    'data.leakage.doc': '文档泄露',
    'data.leakage.code': '代码泄露',
    'copyright.piracy': '版权盗版',
    'dark.web': '暗网威胁',
    'dark.web.exam': '暗网审核'
  }
};
const statusI18n = {
  'en-US': {
    'dict.status.processing': 'Processing',
    'dict.status.finish': 'Finished',
    'dict.status.review.pending': 'Review Pending',
    'dict.status.review.pended': 'Pending',
    'dict.status.reopen.pending.review': 'Re-opened',
    'dict.status.closed': 'Closed',
    'dict.status.review.failed': 'Review Failed',
    'dict.status.cancelled': 'Cancelled'
  },
  'zh-CN': {
    'dict.status.processing': '处置中',
    'dict.status.finish': '已完成',
    'dict.status.review.pending': '待审核',
    'dict.status.review.pended': '已暂停',
    'dict.status.reopen.pending.review': '重开待审核',
    'dict.status.closed': '已关闭',
    'dict.status.review.failed': '审核未通过',
    'dict.status.cancelled': '已撤销'
  }
};
const i18n = {
  'en-US': {
    ...serviceTypeI18n['en-US'],
    ...detailDictI18n['en-US'],
    ...systemManagerI18n['en-US'],
    ...statusI18n['en-US'],
    ...reportI18n['en-US'],
    'menu.TianJi': 'Tianji Partners',
    'menu.service.account': 'Service Account',
    'menu.welcome': 'Welcome',
    'menu.dashboard': 'Overview',
    'menu.list': 'List',
    'menu.result': 'Result',
    'menu.exception': 'Exception',
    'menu.form': 'Form',
    'menu.profile': 'Profile',
    'menu.visualization': 'Data Visualization',
    'menu.user': 'User Center',
    'menu.mybill': 'My Bill',
    'menu.msgntific': 'Notification Configuration',
    'menu.disclaimer': 'Terms of Service',
    'menu.exception.403': '403',
    'menu.exception.404': '404',
    'menu.exception.500': '500',
    'menu.profile.basic': 'Basic Profile',
    'menu.list.cardList': 'Card List',
    'menu.reportCenter': '报告中心',
    'menu.reportCenterDetail': 'Detail',
    'menu.watchReportForm': 'View',
    'menu.allType': 'Search',
    'menu.ticketExam': '工单审核',
    'menu.innerExam': 'Internal Audit',
    'menu.riskDetect': '风险探测',
    'menu.systemLog': 'Audit Log',
    'menu.joinPage': '邀请',
    'menu.visualization.dataAnalysis': 'Analysis',
    'menu.result.error': 'Error',
    'menu.form.group': 'Group Form',
    'menu.dashboard.monitor': 'Real-time Monitor',
    'menu.visualization.multiDimensionDataAnalysis': 'Multi-D Analysis',
    'menu.list.searchTable': 'Search Table',
    'menu.form.step': 'Step Form',
    'menu.result.success': 'Success',
    'menu.user.info': 'User Info',
    'menu.basicInfo': 'My Account',
    'menu.user.setting': 'User Setting',
    'menu.user.switchRoles': 'Switch Roles',
    'menu.user.role.admin': 'Admin',
    'menu.user.role.user': 'General User',
    'menu.dashboard.workplace': 'Workplace',
    'navbar.logout': 'Logout',
    'settings.title': 'Settings',
    'settings.themeColor': 'Theme Color',
    'settings.content': 'Content Setting',
    'settings.navbar': 'Navbar',
    'settings.menuWidth': 'Menu Width (px)',
    'settings.navbar.theme.toLight': 'Click to use light mode',
    'settings.navbar.theme.toDark': 'Click to use dark mode',
    'settings.menu': 'Menu',
    'settings.footer': 'Footer',
    'settings.otherSettings': 'Other Settings',
    'settings.colorWeek': 'Color Week',
    'settings.alertContent': 'After the configuration is only temporarily effective, if you want to really affect the project, click the "Copy Settings" button below and replace the configuration in settings.json.',
    'settings.copySettings': 'Copy Settings',
    'settings.copySettings.message': 'Copy succeeded, please paste to file src/settings.json.',
    'settings.close': 'Close',
    'settings.color.tooltip': '10 gradient colors generated according to the theme color',
    'message.tab.title.message': 'Message',
    'message.tab.title.notice': 'Notice',
    'message.tab.title.todo': 'ToDo',
    'message.allRead': 'All Read',
    'message.seeMore': 'More',
    'message.empty': 'Empty',
    'message.empty.tips': 'No Content',
    'message.lang.tips': 'Language switch to ',
    'navbar.search.placeholder': 'Search for URL/SHA 256/APP Name/Domain/IP/Ticket Number/Leaked Content/Uploader',
    'menu.SystemManager': 'System',
    'menu.SystemManagerOperation': 'System',
    'menu.SystemManagerOperation.staff': 'Staff Management',
    'menu.SystemManagerOperation.customers': 'Customer',
    'menu.ServiceManagement': '服务管理',
    'risk.quntity': 'Quantity',
    'ticket': 'Tickets',
    'risk': 'Risks',
    'riskTotal': 'Total risk',
    'ticketTotal': 'Total ticket',
    'risk.opened': 'Opened',
    'placeholder.select.company': 'Customer',
    'required.select.company': 'Please select customer',
    'select.company.brand': 'Customer & Brand',
    'search.company.brand': 'Search customer & Brand',
    'company.brand': 'Customer & Brand',
    'select.brand': 'Select brand',
    'select.serviceType': 'Risk Categories',
    'select.status': 'Select status',
    'select.risk.level': 'Risk Level',
    'select.risk': 'Please select risk',
    'time.period': 'Period',
    'list.descend': 'DESC',
    'list.ascend': 'ASC',
    'dict.button.back.formoer.version': 'Back to former version',
    'dict.button.close': 'Close',
    'dict.billing.status': 'Bill status'
  },
  'zh-CN': {
    ...serviceTypeI18n['zh-CN'],
    ...detailDictI18n['zh-CN'],
    ...systemManagerI18n['zh-CN'],
    ...statusI18n['zh-CN'],
    ...reportI18n['zh-CN'],
    'menu.TianJi': '天际友盟',
    'menu.service.account': '服务号',
    'menu.dashboard': '风险总览',
    'menu.list': '列表页',
    'menu.result': '结果页',
    'menu.exception': '异常页',
    'menu.form': '表单页',
    'menu.profile': '详情页',
    'menu.reportCenter': '报告中心',
    'menu.systemLog': '审计日志',
    'menu.reportCenterDetail': '详情',
    'menu.watchReportForm': '查看报表',
    'menu.allType': '综合查询',
    'menu.ticketExam': '工单审核',
    'menu.innerExam': '内部审核',
    'menu.riskDetect': '风险探测',
    'menu.visualization': '数据可视化',
    'menu.joinPage': '邀请',
    'menu.user': '个人中心',
    'menu.basicInfo': '基本信息',
    'menu.mybill': '我的账单',
    'menu.msgntific': '通知设置',
    'menu.disclaimer': '服务条款',
    'menu.exception.403': '403',
    'menu.exception.404': '404',
    'menu.exception.500': '500',
    'menu.profile.basic': '基础详情页',
    'menu.list.cardList': '卡片列表',
    'menu.visualization.dataAnalysis': '分析页',
    'menu.result.error': '失败页',
    'menu.form.group': '分组表单',
    'menu.dashboard.monitor': '实时监控',
    'menu.visualization.multiDimensionDataAnalysis': '多维数据分析',
    'menu.list.searchTable': '查询表格',
    'menu.form.step': '分步表单',
    'menu.result.success': '成功页',
    'menu.user.info': '用户信息',
    'menu.user.setting': '用户设置',
    'menu.user.switchRoles': '切换角色',
    'menu.user.role.admin': '管理员',
    'menu.user.role.user': '普通用户',
    'menu.dashboard.workplace': '工作台',
    'navbar.logout': '退出登录',
    'settings.title': '页面配置',
    'settings.themeColor': '主题色',
    'settings.content': '内容区域',
    'settings.navbar': '导航栏',
    'settings.menuWidth': '菜单宽度 (px)',
    'settings.navbar.theme.toLight': '点击切换为亮色模式',
    'settings.navbar.theme.toDark': '点击切换为暗黑模式',
    'settings.menu': '菜单栏',
    'settings.footer': '底部',
    'settings.otherSettings': '其他设置',
    'settings.colorWeek': '色弱模式',
    'settings.alertContent': '配置之后仅是临时生效，要想真正作用于项目，点击下方的 "复制配置" 按钮，将配置替换到 settings.json 中即可。',
    'settings.copySettings': '复制配置',
    'settings.copySettings.message': '复制成功，请粘贴到 src/settings.json 文件中',
    'settings.close': '关闭',
    'settings.color.tooltip': '根据主题颜色生成的 10 个梯度色（将配置复制到项目中，主题色才能对亮色 / 暗黑模式同时生效）',
    'message.tab.title.message': '消息',
    'message.tab.title.notice': '通知',
    'message.tab.title.todo': '待办',
    'message.allRead': '全部已读',
    'message.seeMore': '查看更多',
    'message.empty': '清空',
    'message.empty.tips': '暂无数据',
    'message.lang.tips': '语言切换至 ',
    'navbar.search.placeholder': '搜索条件包含URL/SHA256/应用名称/域名/IP地址/工单号/泄露内容/上传人',
    'menu.SystemManager': '系统管理',
    'menu.SystemManagerOperation': '系统管理',
    'menu.SystemManagerOperation.staff': '员工管理',
    'menu.SystemManagerOperation.customers': '客户管理',
    'menu.ServiceManagement': '服务管理',
    'risk.quntity': '风险数量',
    'ticket': '工单',
    'risk': '风险',
    'riskTotal': '风险总数',
    'ticketTotal': '工单总数',
    'risk.opened': '已开通',
    'placeholder.select.company': '请选择客户',
    'required.select.company': '请选择客户',
    'select.company.brand': '请选择客户&品牌',
    'search.company.brand': '搜索客户&品牌',
    'company.brand': '客户&品牌',
    'select.brand': '请选择品牌',
    'select.serviceType': '请选择场景',
    'select.status': '请选择状态',
    'select.risk.level': '风险等级',
    'select.risk': '请请选择风险',
    'time.period': '时间维度',
    'list.descend': '倒序',
    'list.ascend': '正序',
    'dict.button.back.formoer.version': '跳转到旧版',
    'dict.button.close': '关闭',
    'dict.billing.status': '计费状态'
  }
};
export default i18n;